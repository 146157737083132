/**
 * @author : Herlangga Sefani <https://github.com/gaibz>
 */

'use strict';

const Swal = require("sweetalert2");

function httpError (err) {
    Swal.fire({
        title: `Whoops, Something Happend`,
        text: `${err}`,
        icon: `error`,
        confirmButtonText: `Ok`
    });
}

function apiError (data) {
    Swal.fire({
        title: `Error Found`,
        text: `${data.message}`,
        icon: `error`,
        confirmButtonText: `Ok`
    });
}

module.exports = {httpError, apiError};